import { EntityState, PaginationResult } from 'shared/types';

import { FailureActionPayload } from 'store/errors/types';
import { StandardPaginationResult } from 'shared/types/standard-pagination-result';
import { SpecialVisitType, VisitorFrequencyEnum } from 'components/visit-form/types';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { VisitorType } from 'store/api-client/api-client.types';

export interface VisitsState {
  upcomingVisitsData: VisitState[];
  upcomingVisitsTotal: number | null;

  pastVisitsData: VisitState[];
  pastVisitsTotal: number | null;

  allVisitsData: VisitState[];
  allVisitsTotal: number | null;

  mappedData: Record<string, VisitState>;
  loading: boolean;
  visitorRegistrationInitialized: boolean;

  createVisitQueryState: CreateVisitQueryState;
}

export interface CreateVisitQueryState {
  loading: boolean;
  error: Error | null;
}

export type VisitState = EntityState<Visit | null>;

export type FetchVisitsActionParams = FetchSpecificVisitsActionParams;

export interface FetchSpecificVisitsActionParams {
  buildingId: string;
  limit: number;
  offset: number;
  before?: string;
  after?: string;
}

export interface VisitParams {
  buildingId: string;
  visitId: string;
}

export interface FetchVisitResponse {
  params: VisitParams;
  result: Visit | null;
}

export interface VisitError extends FailureActionPayload {
  params: VisitParams;
}

export interface CancelVisitResult {
  canceled: boolean;
  errors?: Record<string, unknown>;
  message?: string;
}

export interface FetchVisitsParams {
  buildingId: string;
  limit: number;
  offset: number;
  before?: string;
  after?: string;
}

export type PaginationVisits = PaginationResult<Visit> | StandardPaginationResult<Visit, 'visits'>;

export interface FetchVisitsResponse {
  params: FetchSpecificVisitsActionParams;
  result: PaginationVisits;
}

export interface FetchVisitsError extends FailureActionPayload {
  params: FetchSpecificVisitsActionParams;
}

export enum VisitStatus {
  INVITED = 'INVITED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  CANCELED = 'CANCELED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  VISIT_APPROVED = 'VISIT_APPROVED',
  PREREGISTERED = 'PREREGISTERED',
  NO_SHOW = 'NO_SHOW',
}

export interface Visit {
  id: string;
  alt_contact?: string;
  email: string;
  first_name: string;
  last_name: string;
  visit_status: VisitStatus;
  arrival_date: Date;
  visitors?: Visitor[];
  rrule?: string;
  user_id: number | string;
  company_name?: string;
  departure_date?: Date;
  floor?: string;
  suite?: string;
  phone?: string;
  special_visit_type?: SpecialVisitType | null;
  special_status?: string;
  escort_id?: string;
  host: Host;
  visit_type_id?: number | null;
  special_instruction_for_visitor?: string;
  reason_for_visit?: string;
  email_recipients?: string[];
  frequency?: VisitorFrequencyEnum;
  end_date?: Date;
  count?: number;
  custom_fields?: DynamicFormDataDto;
  custom_form?: DynamicFormDto;
  license_plate?: string;
}

export interface Host {
  first_name: string;
  last_name: string;
  email: string;
}

export interface Visitor {
  first_name: string;
  last_name: string;
  visitor_phone?: string;
  id?: string | null;
  email?: string;
  phone?: string;
  type: VisitorType;
  image_url?: string;
  company?: string;
  is_premier?: boolean;
  email_recipients?: string[];
}

export type CreateVisitor = Omit<Visitor, 'type'>;

export enum VisitUiType {
  PAST = 'past',
  UPCOMING = 'upcoming',
  ALL = 'all',
}

export interface CreateVisitDto {
  id?: string;
  user_image_url?: string;
  user_id?: number | string;
  first_name?: string;
  last_name?: string;
  email?: string;
  company_name?: string;
  arrival_date?: Date;
  departure_date?: Date | null;
  purpose_id?: number | string;
  start_date?: Date;
  end_date?: Date;
  floor?: string;
  suite?: string;
  visit_type_id?: number | string;
  special_visit_type?: SpecialVisitType;
  special_instruction_for_escort?: string;
  special_instruction_for_receptionist?: string;
  special_instruction_for_visitor?: string;
  reason_for_visit?: string;
  notify_on_check_in?: boolean;
  check_in_notification_list?: string[];
  rrule?: string;
  visitors: CreateVisitor[];
  checked_in?: Date;
  checked_out?: Date;
  alt_contact?: string;
  status?: number;
  host_email?: string;
  phone?: string;
  escort_id?: string;
  email_recipients?: string[];
  frequency: VisitorFrequencyEnum;
  count: number;
  custom_fields?: DynamicFormDataDto;
  license_plate?: string;
}

export interface CreateVisitParams {
  buildingId: string;
  visitDto: CreateVisitDto;
}

export interface UpdateVisitDto {
  id: string;
  alt_contact?: string | null;
  email: string;
  first_name: string;
  last_name: string;
  visit_status: string;
  arrival_date: Date;
  visitors?: CreateVisitor[] | null;
  rrule?: string | null;
  user_id: number | string;
  company_name?: string | null;
  departure_date?: Date | null;
  floor?: string | null;
  suite?: string | null;
  phone?: string | null;
  special_visit_type?: SpecialVisitType | null;
  special_status?: string | null;
  escort_id?: string | null;
  host: Host;
  visit_type_id?: number | string | null;
  special_instruction_for_visitor?: string | null;
  reason_for_visit?: string | null;
}

export interface UpdateVisitActionParams {
  params: VisitParams;
  payload: Partial<UpdateVisitDto>;
}

export interface UpdateVisitResponse {
  params: VisitParams;
  result: Visit;
}
